import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const ProductDetails = () => {
    const { id } = useParams();
    const [item, setItem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isApproved, setIsApproved] = useState(null); // Initialize with null

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL_PRO}/getpid/${id}`);
                if (response.ok) {
                    const data = await response.json();
                    setItem(data);
                    setIsApproved(data.isApproved); // Set isApproved based on fetched data
                } else {
                    const errorData = await response.json();
                    setError(errorData.message || 'Error fetching product');
                }
            } catch (error) {
                setError('Fetch error: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        if (id) fetchProduct();
    }, [id]);

    const getToken = () => localStorage.getItem('token');

    const productApproval = async () => {
        const isConfirmed = window.confirm(
            `Are you sure you want to ${isApproved ? 'disapprove' : 'approve'} this product?`
        );

        if (isConfirmed) {
            try {
                const token = getToken();
                const newApprovalStatus = !isApproved;
                const response = await axios.patch(
                    `${process.env.REACT_APP_API_URL_PRO}/approval/${item._id}`,
                    { isApproved: newApprovalStatus },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                );
                setIsApproved(newApprovalStatus);
                console.log('Product approval updated successfully:', response.data);
            } catch (error) {
                console.error('Error updating product approval:', error);
            }
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
    if (!item) return <p>No product found</p>;

    return (
        <div className="bg-gray-50 text-gray-800">
            <div className="container mx-auto p-6">
                <div className="grid md:grid-cols-2 gap-6">
                    {/* Product Images */}
                    <div className="space-y-4">
                        <img
                            src={item && item.images}
                            alt="Product Image"
                            className="w-full h-80 object-cover rounded-lg shadow-lg"
                        />
                        <div className="flex space-x-4">
                            <img
                                src={item && item.images}
                                alt="Thumbnail 1"
                                className="w-1/4 h-20 object-cover rounded-lg border border-gray-200 cursor-pointer"
                            />
                            <img
                                src={item && item.images}
                                alt="Thumbnail 2"
                                className="w-1/4 h-20 object-cover rounded-lg border border-gray-200 cursor-pointer"
                            />
                        </div>
                    </div>

                    {/* Product Details */}
                    <div className="space-y-4">
                        <h1 className="text-3xl font-bold text-blue-800">
                            <span className='text-xl text-gray-600'>Title: </span>
                            {item && item.title}
                        </h1>
                        <p className="text-lg text-gray-700">
                            <span className='text-xl text-gray-600'>Description: </span>
                            {item && item.description}
                        </p>

                        <div className="flex items-center space-x-4">
                            <span className="text-xl font-semibold text-green-600">₹{item && item.price}</span>
                            <span className="text-sm text-green-500">In Stock: {item && item.quantity}</span>
                        </div>

                        <div className="bg-white p-4 rounded-lg shadow-lg dark:bg-boxdark dark:text-bodydark">
                            <h2 className="text-xl font-semibold text-gray-800">Specifications</h2>
                            <ul className="list-disc pl-5 space-y-2 text-gray-700">
                                {item && item.specifications.map((spec, index) => (
                                    <li key={index}>
                                        <strong className="text-blue-600">{spec.title}:</strong> {spec.desc}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="bg-white p-4 rounded-lg shadow-lg mt-4 dark:bg-boxdark dark:text-bodydark">
                            <h2 className="text-xl font-semibold text-gray-800">
                                Warranty: <span className='text-sm text-orange-400'>{item && item.warranty}</span>
                            </h2>
                        </div>

                        <div className="relative flex items-center space-x-4 mt-4 bg-white p-4 rounded-lg shadow-lg dark:bg-boxdark dark:text-bodydark">
                            <h4 className='text-xl font-semibold'>Product Approved :</h4>
                            <div className="relative flex items-center">
                                <span className={`p-1.5 text-xs font-medium uppercase tracking-wider ${isApproved ? 'text-green-800 bg-green-200 dark:text-green-200 dark:bg-green-600' : 'text-red-800 bg-red-200 dark:text-red-200 dark:bg-red-600'} rounded-lg`}>
                                    {isApproved ? "Approved" : "Not-Approved"}
                                </span>
                            </div>
                            <span
                                className="absolute top-0 right-0 text-xs text-blue-500 cursor-pointer hover:underline"
                                title="Click to toggle product approval"
                                onClick={productApproval}
                            >
                                Click Approval
                            </span>
                        </div>

                        <div className="bg-white p-4 rounded-lg shadow-lg mt-4 dark:bg-boxdark dark:text-bodydark">
                            <h2 className="text-xl font-semibold text-gray-800">Vendor Details</h2>
                            <p><strong className="text-blue-600">Name:</strong> {item && item.vendorID.username}</p>
                            <p><strong className="text-blue-600">Email:</strong> {item && item.vendorID.email}</p>
                            <p><strong className="text-blue-600">Phone:</strong> {item && item.vendorID.phone}</p>
                            <p><strong className="text-blue-600">GST Number:</strong> {item && item.vendorID.gstNumber}</p>
                        </div>
                    </div>
                </div>

                {/* Address Information */}
                <div className="bg-white p-4 rounded-lg shadow-lg mt-6 dark:bg-boxdark dark:text-bodydark">
                    <h2 className="text-xl font-semibold text-gray-800">Location</h2>
                    <p><strong className="text-blue-600">Country:</strong> {item && item.country}</p>
                    <p><strong className="text-blue-600">State:</strong> {item && item.state}</p>
                    <p><strong className="text-blue-600">City:</strong> {item && item.city}</p>
                    <p><strong className="text-blue-600">Nearby:</strong> {item && item.nearby}</p>
                    <p><strong className="text-blue-600">Postal Code:</strong> {item && item.postal_code}</p>
                </div>
            </div>
        </div>
    );
};

export default ProductDetails;

import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";

const dummyData = [
  {
    id: 1,
    username: "JohnDoe",
    image: "pic",
    phone: "+1234567890",
    productTitle: "Kring New Fit office chair",
    productCategory: "Office Furniture",
    description: "Mesh + PU, black",
    status: "Delivered",
    date: "16/10/2021",
    total: "$200.00",
  },
  {
    id: 2,
    username: "JaneDoe",
    image: "pic",
    phone: "+0987654321",
    productTitle: "Kring New Fit office chair",
    productCategory: "Office Furniture",
    description: "Mesh + PU, black",
    status: "Shipped",
    date: "16/10/2021",
    total: "$200.00",
  },
  {
    id: 3,
    username: "AliceSmith",
    image: "pic",
    phone: "+1122334455",
    productTitle: "Kring New Fit office chair",
    productCategory: "Office Furniture",
    description: "Mesh + PU, black",
    status: "Cancelled",
    date: "16/10/2021",
    total: "$200.00",
  },
];

const EnquiriesVendor = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(2); // Number of items per page
  const [searchQuery, setSearchQuery] = useState("");

  // Calculate total pages
  const totalPages = Math.ceil(dummyData.length / itemsPerPage);

  // Filtered data based on search query
  const filteredData = dummyData.filter((item) =>
    Object.values(item).some((val) =>
      val.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  // Get current items for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  return (
    <div className="p-5 h-screen bg-gray-100 dark:bg-gray-900">
      <h1 className="text-xl mb-2 text-gray-800 dark:text-gray-100">Product Enquiries by Buyer</h1>

      <div className="mb-4 relative">
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="w-full p-2 pl-10 text-gray-700 dark:bg-boxdark dark:text-bodydark rounded-lg focus:outline-none"
                />
                <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-gray-400" />
            </div>

      {/* Table for larger screens */}
      <div className="overflow-auto rounded-lg shadow-lg hidden md:block bg-white dark:bg-boxdark dark:text-bodydark">
        <table className="w-full">
          <thead className="bg-gray-50 border-b-2 border-gray-200 dark:bg-gray-700 dark:border-gray-600">
            <tr>
              <th className="w-20 p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">S.No</th>
              <th className="w-20 p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">Image</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">Username</th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">Phone</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">Product Title</th>
              <th className="w-32 p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">Product Category</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">Description</th>
              <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">Date</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100 dark:divide-gray-700">
            {currentItems.map((item, index) => (
              <tr key={item.id} className={index % 2 === 0 ? "bg-gray-50 dark:bg-gray-700" : ""}>
                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">{item.id}</td>
                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">{item.image}</td>
                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">{item.username}</td>
                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">{item.phone}</td>
                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">{item.productTitle}</td>
                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">{item.productCategory}</td>
                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">{item.description}</td>
                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">{item.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Grid for smaller screens */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:hidden">
        {currentItems.map((item) => (
          <div key={item.id} className="space-y-3 p-4 rounded-lg dark:bg-boxdark-2 dark:text-bodydark shadow-lg">
            <div className="flex items-center space-x-2 text-sm">
              <div>
                <a href="#" className="text-blue-500 font-bold hover:underline dark:text-blue-400">#{item.id}</a>
              </div>
              <div className="text-gray-500 dark:text-gray-400">{item.image}</div>
              <div className="text-gray-500 dark:text-gray-400">{item.username}</div>
              <div className="text-gray-500 dark:text-gray-400">{item.phone}</div>
            </div>
            <div className="text-sm text-gray-700 dark:text-gray-300">
              {item.productTitle}
            </div>
            <div className="text-sm text-gray-500 dark:text-gray-400">
              {item.productCategory}
            </div>
            <div className="text-sm text-gray-500 dark:text-gray-400">
              {item.description}
            </div>
            <div className="text-sm text-gray-500 dark:text-gray-400">
              {item.date}
            </div>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 mx-1 text-white bg-blue-500 rounded disabled:opacity-50 dark:bg-blue-400"
        >
          Previous
        </button>
        <span className="px-4 py-2 mx-1 text-gray-700 dark:text-gray-300">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-4 py-2 mx-1 text-white bg-blue-500 rounded disabled:opacity-50 dark:bg-blue-400"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default EnquiriesVendor;

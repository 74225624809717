import React, { useState } from "react";
import { RiMoneyRupeeCircleFill } from 'react-icons/ri';
import { FaBoxOpen, FaTasks, FaStar, FaUser } from 'react-icons/fa'; // Importing relevant icons

const cardData = [
    {
        id: 1,
        title: 'Products in Stock',
        quantity: 120,
        icon: <FaBoxOpen className="h-6 w-6 text-green-500" />
    },
    {
        id: 2,
        title: 'Pending Orders',
        quantity: 35,
        icon: <FaTasks className="h-6 w-6 text-yellow-500" />
    },
    {
        id: 3,
        title: 'Total Sales',
        quantity: 5420,
        icon: <RiMoneyRupeeCircleFill className="h-6 w-6 text-blue-500" />
    },
    {
        id: 4,
        title: 'Product Rating',
        quantity: 5,
        icon: <FaStar className="h-6 w-6 text-orange-500" />
    },
    {
        id: 5,
        title: 'Seller Rating',
        quantity: 5,
        icon: <FaStar className="h-6 w-6 text-purple-500" />
    },
    {
        id: 6,
        title: 'Enquiries',
        quantity: 40,
        icon: <FaUser className="h-6 w-6 text-red-500" />
    },
];

const DashboardVendor = () => {

    return (
        <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-6">
            <h1 className="text-3xl font-bold mb-8 text-center dark:text-green-300">Dashboard Vendor Page</h1>
            <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {cardData.map(card => (
                    <div
                        key={card.id}
                        className="relative dark:bg-boxdark dark:text-bodydark p-6 rounded-lg shadow-md ring-1 ring-green-600/35 dark:ring-green-700 transition-transform duration-300 hover:scale-105"
                    >
                        <div className="flex items-center space-x-4">
                            <div className="flex-shrink-0 h-12 w-12 bg-gray-200 rounded-full flex items-center justify-center text-white">
                                {card.icon}
                            </div>
                            <div>
                                <h3 className="text-lg font-semibold">{card.title}</h3>
                                <p className="text-2xl font-bold">{card.quantity}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DashboardVendor;

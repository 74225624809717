import React from "react";
import { RiMoneyRupeeCircleFill } from 'react-icons/ri';
import { FaBoxOpen, FaTasks, FaStar, FaUser, FaUserShield, FaUserAlt, FaFileInvoice, FaFileAlt } from 'react-icons/fa'; // Importing relevant icons

const cardData = [
    {
        id: 1,
        title: 'Total Products in Stock',
        quantity: 120,
        icon: <FaBoxOpen className="h-6 w-6 text-green-500" />
    },
    {
        id: 2,
        title: 'Total Pending Orders',
        quantity: 35,
        icon: <FaTasks className="h-6 w-6 text-yellow-500" />
    },
    {
        id: 3,
        title: 'Total Sales',
        quantity: 5420,
        icon: <RiMoneyRupeeCircleFill className="h-6 w-6 text-blue-500" />
    },
    {
        id: 6,
        title: 'Enquiries',
        quantity: 40,
        icon: <FaUser className="h-6 w-6 text-red-500" />
    },
    {
        id: 7,
        title: 'Buyers',
        quantity: 200,
        icon: <FaUserAlt className="h-6 w-6 text-teal-500" />
    },
    {
        id: 8,
        title: 'Vendors',
        quantity: 150,
        icon: <FaUserShield className="h-6 w-6 text-indigo-500" />
    },
    {
        id: 9,
        title: 'Report Buyer',
        quantity: 25,
        icon: <FaFileInvoice className="h-6 w-6 text-cyan-500" />
    },
    {
        id: 10,
        title: 'Report Vendor',
        quantity: 30,
        icon: <FaFileAlt className="h-6 w-6 text-lime-500" />
    },
    {
        id: 11,
        title: 'Blocked Users',
        quantity: 5,
        icon: <FaUserAlt className="h-6 w-6 text-red-700" />
    },
];

const DashboardAdmin = () => {
    return (
        <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-6">
            <h1 className="text-3xl font-bold mb-8 text-center dark:text-green-300">Admin Dashboard</h1>
            <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {cardData.map(card => (
                    <div
                        key={card.id}
                        className="relative dark:bg-boxdark dark:text-bodydark p-6 rounded-lg shadow-md ring-1 ring-green-600/35 dark:ring-green-700 transition-transform duration-300 hover:scale-105"
                    >
                        <div className="flex items-center space-x-4">
                            <div className="flex-shrink-0 h-12 w-12 bg-gray-200 rounded-full flex items-center justify-center text-white">
                                {card.icon}
                            </div>
                            <div>
                                <h3 className="text-lg font-semibold">{card.title}</h3>
                                <p className="text-2xl font-bold">{card.quantity}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DashboardAdmin;

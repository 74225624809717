import React from "react";
import { MdOutlineChat, MdOutlinePermPhoneMsg } from "react-icons/md";
import { LiaRocketchat } from "react-icons/lia";

const EnquiryBuyer = () => {
    return (
        <div id="enquiry" data-aos="fade-up" className='flex flex-col md:flex-row w-full bg-white'>
            <div className='dark:bg-boxdark-2 dark:text-bodydark w-full md:w-1/2'>
                <h2 className='text-lg font-medium text-center mt-10'>Get your <span className='text-green-700 font-bold text-xl'>Agri</span> Products in 3 easy steps</h2>
                <div className='grid lg:grid-cols-3 gap-3 mt-5 justify-center text-success'>
                    <section className='bg-white dark:bg-boxdark shadow-md dark:shadow-none  rounded-lg  px-2 mx-2'>
                        <p className='text-center p-2'>Step 1</p>
                        <div className='text-center'>
                            <MdOutlineChat className="lg:text-8xl text-6xl ms-5 text-black dark:text-bodydark" />
                        </div>
                        <div className='pb-4'>
                            <span className=''>Post Anything you want</span>
                        </div>
                    </section>
                    <section className='bg-white dark:bg-boxdark shadow-md dark:shadow-none  rounded-lg  px-2 mx-2'>
                        <p className='text-center p-2'>Step 2</p>
                        <div className='text-cente'>
                            <LiaRocketchat className="text-8xl ms-5 text-black dark:text-bodydark" />
                        </div>
                        <div className='pb-4'>
                            <span className=''>Get Response from sellers</span>
                        </div>
                    </section>
                    <section className='bg-white dark:bg-boxdark shadow-md dark:shadow-none  rounded-lg  px-2 mx-2'>
                        <p className='text-center p-2'>Step 3</p>
                        <div className='text-cente'>
                            <MdOutlinePermPhoneMsg className="text-8xl ms-5 text-black dark:text-bodydark" />
                        </div>
                        <div className='pb-4'>
                            <span className=''>Contact and make yours</span>
                        </div>
                    </section>
                </div>
                <div className="mt-8 px-10">
                    <h4 className="md:text-5xl text-3xl mb-8">Get <b className="text-success">free</b> quotes from multiple sellers</h4>
                </div>
            </div>

            <div className='dark:bg-boxdark-2 dark:text-bodydark w-full md:w-1/2 mr-3'>
                <h2 className='text-xl font-medium text-center mt-10 mb-5'>Drop your <span className='text-green-700 font-bold text-xl'>Requirement</span> to different Agriculture Suppliers</h2>

                <div className='mb-10 mx-5 shadow-lg bg-gray-200 border rounded-lg'>
                    <div className="text-center m-5">
                        <form>
                            <input
                                className="w-full mb-3 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-success dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-success"
                                required
                                placeholder="Product Name / Service Name"
                            />

                            <select
                                className="w-full mb-3 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-success dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-success"
                            >
                                <option value="">select your product category</option>
                                <option value="Input">Input</option>
                                <option value="Output">Output</option>
                                <option value="Agri Service">Agri Service</option>
                            </select>

                            <div>
                                <textarea
                                    type="text"
                                    name="description"
                                    placeholder='Describe about product / services'
                                    rows={5}
                                    className="w-full mb-3 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-success dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-success"
                                />
                            </div>

                            <button
                                type='submit'
                                className=" w-full inline-flex items-center justify-center gap-2 px-4 py-3 text-sm font-semibold text-white transition-all bg-green-500 border border-transparent rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                            >
                                Post Requirement
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default EnquiryBuyer;
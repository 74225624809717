// Spinner.js
const Spinner = () => {
    return (
        <div className="flex justify-center items-center h-full mt-18">
            <div className="border-t-4 border-success border-solid border-gray-200 rounded-full w-24 h-24 animate-spin"></div>
        </div>
    );
};

export default Spinner;

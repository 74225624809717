import React from 'react';

const VendorEnquiry = ({ onConfirm, onCancel }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white md:w-[50%] dark:bg-boxdark p-6 rounded-lg shadow-lg">
                <h4 className="text-lg mb-4 text-center text-success font-bold">Tell us your product requirement to direct seller</h4>
                <div>
                    <input
                        className="w-full mb-3 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-success dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-success"
                        required
                        placeholder="Product Name / Service Name"
                    />
                </div>
                <div>
                    <textarea
                        type="text"
                        name="description"
                        placeholder='Describe about product / services'
                        rows={5}
                        className="w-full mb-3 rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-success dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-success"
                    />
                </div>
                <div className="flex justify-end">
                    <button
                        onClick={onCancel}
                        className="px-4 py-2 border border-slate-200 rounded-lg mr-2 hover:bg-slate-100 dark:bg-boxdark dark:hover:bg-slate-600"
                    >
                        Cancel
                    </button>
                    <button
                        className="px-4 py-2 bg-green-500 rounded-lg text-white hover:bg-green-600"
                        onClick={onConfirm}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VendorEnquiry;

import React,{ useState, useEffect } from "react";
import { Link } from "react-router-dom";

const SerachBox = () => {
    const [cities, setCities] = useState([]);
    const [selectedcity, setSelectedCity] = useState('ALL');
    const [selectedProduct, setSelectedProduct] = useState('');


    useEffect(() => {
        // Function to fetch cities from the API
        const fetchCities = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL_PRO}/allcity`);

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (data.success) {
                    setCities(data.cities);

                } else {
                    console.log(data.message || 'Failed to fetch cities');
                }
            } catch (error) {
                console.log(error.message);
            }
        };

        fetchCities();
    }, []);


    return(
        <div className="flex justify-center mt-5 mb-5">
        {/* Dropdown for Cities */}
        <div className="relative">
            <select
                className="inline-flex items-center px-8 py-2 bg-orange-500 text-white
                rounded-l-md shadow-sm hover:bg-white hover:text-orange-500 focus:outline-none border"
                onChange={(e)=>setSelectedCity(e.target.value)}
            >
                <option value="">ALL</option>
                {cities.map((city) => (
                    <option key={city} value={city}>
                        {city}
                    </option>
                ))}
            </select>
        </div>

        {/* Search Input Field */}
        <input
            type="text"
            id="text-input"
            placeholder="please search product in your city...... "
            className="bg-white  dark:bg-boxdark dark:text-bodydark1 shadow-md dark:shadow-none py-2 lg:px-12 lg:w-125 w-full font-bold 
            focus:outline-none sm:text-sm"
            value={selectedProduct}
            onChange={(e)=>setSelectedProduct(e.target.value)}
        />

        {/* Search Button */}
        <Link to={`/products/location/${selectedcity}/${selectedProduct}`}
            className="inline-flex items-center lg:px-4 lg:py-2 text-white bg-green-600
             hover:bg-green-800 text-gray-700 rounded-r-md shadow-sm hover:bg-gray-100
              sm:px-2 sm:py-1"
        >
            Search
        </Link>
    </div>
    )
};
export default SerachBox;
import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className=" bottom-0 bg-success text-white w-full px-4 border-t-4 dark:bg-boxdark dark:text-bodydark shadow-md dark:shadow-none">
      <div className="container  flex flex-col justify-between py-10 mx-auto space-y-8 lg:flex-row lg:space-y-0">
        <div className="lg:w-1/3">
          <a rel="noopener noreferrer" href="#" className="flex justify-center space-x-3 lg:justify-start">
            <div className="flex items-center justify-center w-16 h-16">
              <img
                src="https://res.cloudinary.com/dq7vggsop/image/upload/v1711532480/jfqcm2s1ekxloucjk72p.png"
                alt="Logo"
              />
            </div>
            <span className="self-center text-xl font-semibold">Karnataka Agribusiness</span>
          </a>
        </div>
        <div className="grid grid-cols-2 text-sm gap-x-3 gap-y-8 lg:w-2/3 sm:grid-cols-4">
          <div className="space-y-3">
            <h3 className="tracking-wide uppercase dark:text-gray-900">About Us</h3>
            <ul className="space-y-1">
              <li><a rel="noopener noreferrer" href="#">Features</a></li>
              <li><a rel="noopener noreferrer" href="#">Integrations</a></li>
              <li><a rel="noopener noreferrer" href="#">Pricing</a></li>
              <li><a rel="noopener noreferrer" href="#">FAQ</a></li>
            </ul>
          </div>
          <div className="space-y-3">
            <h3 className="tracking-wide uppercase dark:text-gray-900">Help</h3>
            <ul className="space-y-1">
              <li><a rel="noopener noreferrer" href="#">Feedback</a></li>
              <li><a rel="noopener noreferrer" href="#">Complaints</a></li>
              <li><a rel="noopener noreferrer" href="#">Customer Care</a></li>
              <li><a rel="noopener noreferrer" href="#">Contact Us</a></li>
            </ul>
          </div>
          <div className="space-y-3">
            <h3 className="uppercase dark:text-gray-900">Sppliers Tool Kit</h3>
            <ul className="space-y-1">
              <li><a rel="noopener noreferrer" href="#">Public API</a></li>
              <li><a rel="noopener noreferrer" href="#">Documentation</a></li>
              <li><a rel="noopener noreferrer" href="#">Guides</a></li>
            </ul>
          </div>
          <div className="space-y-3">
            <h3 className="uppercase dark:text-gray-900">Buyers Tool Kit</h3>
            <ul className="space-y-1">
              <li><a rel="noopener noreferrer" href="#">Public API</a></li>
              <li><a rel="noopener noreferrer" href="#">Documentation</a></li>
              <li><a rel="noopener noreferrer" href="#">Guides</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center py-5">
      <div className="text-center flex-grow">
        <p className="text-sm">&copy; 2024 Your Company. All rights reserved.</p>
      </div>
      <div className="flex space-x-4">
        <a href="#" className="" aria-label="Facebook" title='Facebook'>
          <FaFacebookF />
        </a>
        <a href="#" className="" aria-label="Twitter" title='Twitter'>
          <FaTwitter />
        </a>
        <a href="#" className="" aria-label="Instagram" title='Instagram'>
          <FaInstagram />
        </a>
      </div>
    </div>

    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

// Update Icons to an array
const Icons = [
  "https://res.cloudinary.com/dm71xhdxd/image/upload/v1722493862/Capture_wg3tob.png",
  "https://res.cloudinary.com/dm71xhdxd/image/upload/v1722494946/Capture2_kaizot.png",
];
const Icons2 = [
  "https://res.cloudinary.com/dm71xhdxd/image/upload/v1722495439/Capture4_ynegrt.png",
  "https://res.cloudinary.com/dm71xhdxd/image/upload/v1722496047/Capture5_cooht0.png"
];

const CategorySection = () => {
  const [data, setData] = useState([]);
  const [cat, setCat] = useState([]);
  const [hoveredCategoryId, setHoveredCategoryId] = useState(null);
  const [hovered, setHovered] = useState(false);
  const [catvalue, setCatValue] = useState('');
  const [firstcatvalue, setFirstCatValue] = useState('');
  const [secondcatvalue, setSecondCatValue] = useState('');


  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryResponse = await fetch(`${process.env.REACT_APP_API_URL}/category/get`);
        const agriservicesResponse = await fetch(`${process.env.REACT_APP_API_URL}/category/Agriservices`);

        if (!categoryResponse.ok || !agriservicesResponse.ok) {
          throw new Error(`HTTP error! Status: ${categoryResponse.status || agriservicesResponse.status}`);
        }

        const categoryData = await categoryResponse.json();
        const agriservicesData = await agriservicesResponse.json();

        setCatValue(agriservicesData.name);

        setData(categoryData);
        setCat(agriservicesData.subcategoriesID);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleMouseEnter = (id) => {
    setHoveredCategoryId(id);
    setHovered(true);
  };

  const handleMouseEnter2 = (id) => {
    setHoveredCategoryId(id);
    setHovered(false);
  };

  const handleMouseLeave = () => setHoveredCategoryId(null);

  const getsubcategoriesID = (id) => {
    const category = data.find(cat => cat._id === id);
    return category ? category.subcategoriesID : [];
  };

  const getsubsubcategoriesID = (id) => {
    const subcategory = cat.find(cat => cat._id === id);
    return subcategory ? subcategory.subsubcategoriesID : [];
  };

  useEffect(() => {
    if (data.length === 0) {
      return;
    }

    const category = data.find(cat => cat._id === hoveredCategoryId);

    if (category) {
      setFirstCatValue(category.name);
    } else {
      setFirstCatValue('');
    }
  }, [hoveredCategoryId, data]);



  useEffect(() => {
    if (cat.length === 0) {
      return;
    }

    const subcategory = cat.find(cat => cat._id === hoveredCategoryId);

    if (subcategory) {
      setSecondCatValue(subcategory.name);
    } else {
      setSecondCatValue('');
    }
  }, [hoveredCategoryId, cat]);

  return (
    <>
      <div
        className="mb-2"
        onMouseLeave={handleMouseLeave}>
        <div className="grid lg:grid-cols-5 sm:grid-cols-2 mx-auto gap-2 p-1 bg-white dark:bg-boxdark shadow-md dark:shadow-none cursor-pointer">
          {data.map((category, index) => (
            <div
              key={category._id}
              className="flex space-x-2 space-y-5 ms-10 hover:bg-green-500 hover:text-white"
              onMouseEnter={() => {
                handleMouseEnter(category._id);
              }}
            >
              <img
                src={Icons[index % Icons.length]} // Show icons one by one
                className="w-16 h-16 rounded-full"
                alt="icon"
              />
              <Link
                to={`/products/${category.name}`}
                className="font-bold text-sm"
              >
                {category.name.substring(4)}
              </Link>
            </div>
          ))}

          {cat.map((subcategory, index) => (
            <div
              key={subcategory._id}
              className="flex space-x-2 space-y-5 ms-10 hover:bg-green-500 hover:text-white"
              onMouseEnter={() => handleMouseEnter2(subcategory._id)}
            >
              <img
                src={Icons2[index % Icons2.length]} // Show icons one by one
                className="w-16 h-16 rounded-full"
                alt="icon"
              />
              <Link to={`/products/${catvalue}/${subcategory.name}`}
              className="font-bold text-sm"
              >
                {subcategory.name}
              </Link>
            </div>
          ))}

          <div className="flex me-10 p-4 hover:bg-green-500 hover:text-white">
            <Link
              to="/products"
              className="flex font-bold">
              All Categories
              <IoIosArrowForward className="mt-1 ms-3" />
            </Link>
          </div>

        </div>
        {hoveredCategoryId && (
          <div className={`flex justify-between bg-gray-100 p-4 border-t-2 bg-white dark:bg-boxdark shadow-md dark:shadow-none mb-2 cursor-pointer
          ${!hovered ? 'hidden' : 'block'}`}>
            {getsubcategoriesID(hoveredCategoryId).map((subcategory) => (
              <div key={subcategory._id} className="p-2">
                <h4 className="font-bold text-[13px]">{subcategory.name}</h4>
                <div className="">
                  {subcategory.subsubcategoriesID && subcategory.subsubcategoriesID.length > 0 ? (
                    subcategory.subsubcategoriesID.map((sub) => (
                      <Link to={`/products/${firstcatvalue}/${subcategory.name}`} key={sub.id}>
                        <p className={`p-2 text-[12px] hover:text-success hover:underline
                          ${sub.subsescategoryID.length > 0 ? 'block' : 'hidden'}`}>
                          {sub.name}
                        </p>
                      </Link>
                    ))
                  ) : (
                    <p>No subcategoriesID available</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}

        {hoveredCategoryId && (
          <div className={`flex justify-between bg-gray-100 p-4 border-t-2 bg-white dark:bg-boxdark shadow-md dark:shadow-none mb-2 cursor-pointer
            ${!hovered ? 'block' : 'hidden'}`}>
            {getsubsubcategoriesID(hoveredCategoryId).map((subsubcategory) => (
              <div key={subsubcategory._id} className="p-2">
                <h4 className="font-bold text-[13px]">{subsubcategory.name}</h4>
                <div className="">
                  {subsubcategory.subsescategoryID && subsubcategory.subsescategoryID.length > 0 ? (
                    subsubcategory.subsescategoryID.map((sub) => (
                      <Link to={`/products/${catvalue}/${secondcatvalue}`} key={sub.id}>
                        <p className="p-2 text-[12px] hover:text-success hover:underline">
                          {sub.name}
                        </p>
                      </Link>
                    ))
                  ) : (
                    <p>No subcategoriesID available</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default CategorySection;

import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PhoneVerify from './Components/commons/phoneVerify';
import Home from './Components/buyer/home/home';
import Header from './Components/buyer/Header/index';
import LoginRoles from './Components/commons/loginRoles';
import UserProfile from './Components/buyer/profile/userProfile';
import Footer from './Components/commons/footer';
import EnquiryBuyer from './Components/buyer/home/enquiry';
import CategoriesWiseProducts from './Components/buyer/product/categoryWise';


const Buyer = ({ user }) => {

  return (
    <Router>
      <div className=" text-black dark:bg-boxdark-2 dark:text-bodydark">
        <Header user={user} />
        <main className="max-w-screen-2xl">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/register" element={<PhoneVerify />} />
            <Route exact path="/login" element={<LoginRoles />} />
            <Route exact path="/forgot-password" element={<PhoneVerify isforgot={true}/>} />
            <Route exact path="/buyer/profile" element={<UserProfile user={user} />} />
            <Route exact path="/products/:slug" element={<CategoriesWiseProducts />} />
            <Route exact path="/products/:slug/:subslug" element={<CategoriesWiseProducts />} />
            <Route exact path="/products/location/:city/:productname" element={<CategoriesWiseProducts />} />
            <Route exact path="/products" element={<CategoriesWiseProducts />} />
            <Route exact path="/products/:category/:slug" element={<CategoriesWiseProducts />} />
          </Routes>
        </main>
        <div>
          <EnquiryBuyer />
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default Buyer;
